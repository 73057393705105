/**
 * Initialize mv splide
 */

declare var Splide: any

const splide = () => {
  const splide = document.querySelector('.js-mvsplide')
  
  if(splide) {
    let splider = new Splide(splide, {
      type: 'fade',
      rewind: true,
      autoplay: true,
      interval: 7000,
      arrows: false,
      pauseOnHover: false
    });

    splider.on( 'ready', function() {
      let isnext = document.querySelector('.is-next');

      if(isnext) {
        const element = isnext.querySelector('.hero-title');
        element!.classList.remove('aos-init', 'aos-animate');
    
        const firstTitle = isnext.querySelector('.first-title'); 
        firstTitle!.classList.remove('aos-init', 'aos-animate');
    
        const secondTitle = isnext.querySelector('.second-title');
        secondTitle!.classList.remove('aos-init', 'aos-animate');
    
        const thirdTitle = isnext.querySelector('.third-title');
        thirdTitle?.classList.remove('aos-init', 'aos-animate');
      }
    })

    splider.on( 'active', (Slide: { slide: { querySelector: (arg0: string) => any; }; }) => {
      const heroTitle = Slide.slide.querySelector('.hero-title');
      heroTitle.classList.add('aos-init', 'aos-animate');

      const firstTitle = Slide.slide.querySelector('.first-title'); 
      firstTitle.classList.add('aos-init', 'aos-animate');

      const secondTitle = Slide.slide.querySelector('.second-title');
      secondTitle.classList.add('aos-init', 'aos-animate');

      const thirdTitle = Slide.slide.querySelector('.third-title');
      thirdTitle?.classList.add('aos-init', 'aos-animate');
    } );

    splider.on( 'inactive', (Slide: { slide: { querySelector: (arg0: string) => any; }; }) => {
      const element = Slide.slide.querySelector('.hero-title');
      element.classList.remove('aos-init', 'aos-animate');

      const firstTitle = Slide.slide.querySelector('.first-title'); 
      firstTitle.classList.remove('aos-init', 'aos-animate');

      const secondTitle = Slide.slide.querySelector('.second-title');
      secondTitle.classList.remove('aos-init', 'aos-animate');

      const thirdTitle = Slide.slide.querySelector('.third-title');
      thirdTitle?.classList.remove('aos-init', 'aos-animate');
    } );
  
    splider.mount();
  }
}

export default splide
