import membership from "./components/membership"
import splide from "./components/splide"


document.addEventListener(
  'DOMContentLoaded',
  () => {
    membership()
    splide()
  },
  false
)
