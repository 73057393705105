/**
 * Toggle active membership tab
 */
const membership = () => {
  const button = document.querySelectorAll('.js-membership-button')
  const tab = document.querySelectorAll('.js-membership-tab')
  
  if(button) {
    function clear() {
      button.forEach(item=>{
        item.classList.remove('active')
      })

      tab.forEach(item=>{
        item.classList.remove('active')
      })
    }

    for(let i=0; i<button.length; i++) {
      button[i].addEventListener('click', function() {
        clear()
        button[i].classList.add('active')
        tab[i].classList.add('active')
      })
    }
  }
}

export default membership